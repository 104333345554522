<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' :'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{$t(`creator.service_focus_in_${content_type.value}`).main_title}}</h1>
        <p>{{$t(`creator.service_focus_in_${content_type.value}`).main_subtitle}}</p>
      </b-col>
      <b-col 
        class="col-12 col-md-6 mt-1" 
        v-for="(i, index) in $t(`creator.service_focus_in_${content_type.value}`).options" 
        :key="index"
        ref="card_radio_button"
        @click="getActive(i, index)"
      >
        <card-radio-button :text="i.text"></card-radio-button>
      </b-col>
      <b-col class="col-12 d-flex justify-content-center">
        <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="selected === null || selected.length === 0" @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue';
import cardRadioButton from '@/views/components/proposals/cardRadioButton.vue';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'stepServiceOptions',
  components: {
    BRow,
    BCol,
    BButton,
    cardRadioButton,
  },
  data() {
    return {
      selected: [],
      content_type: {
        text: ''
      },
      steps: null,
      casting_call: {},
      proposal: {}
    }
  },
  created() {
    setTimeout(() => {
      this.getData();
    }, 100);
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getActive(value, index) {
      if (!this.selected.find((i) => i.value === value.value)) {
        this.$refs.card_radio_button[index].classList.add('active-class-button');
        value.index_actived = index;
        this.selected.push(value);
      } else {
        this.$refs.card_radio_button[index].classList.remove('active-class-button');
        this.selected = this.selected.filter((s) => s.value !== value.value)
      }
    },
    clearActive() {
      for (let index = 0; index < this.$t(`creator.service_focus_in_${this.content_type.value}`).options.length; index++) {
        this.$refs.card_radio_button[index].classList.remove('active-class-button')
      }
    },
    getFormatDataForSteps(data) {
      this.content_type = this.$t('creator.type_of_service').find((type) => type.value === data.content_type);
      //Obtaining indexes activated
      const work_types = [];
      data.work_types.forEach((type) => {
        let work_type = this.$t(`creator.service_focus_in_${data.content_type}`).options.find((op) => op.value === type)
        const index_activated = (this.$t(`creator.service_focus_in_${data.content_type}`).options.indexOf(work_type));
        work_type = {...work_type, index_actived: index_activated}
        work_types.push(work_type);
      });
      setTimeout(() => {
        work_types.forEach((work) => {
          this.getActive(work, work.index_actived);
        });
      }, 200);
    },
    getData() {
      if (this.has_slug) {
        this.casting_call = this.$route.params.casting_call_data;
        this.getFormatDataForSteps(this.casting_call);
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.content_type = this.steps.content_type;
        if (this.steps.work_types) {
          setTimeout(() => {
            this.steps.index_actived[1].map((i, index) => {
              this.getActive(this.steps.work_types[index], i)
            })
          }, 200);
        }
      }
    },
    save() {
      if (this.has_slug) {
        const form_data = new FormData();
        this.selected.map((s) => {
          form_data.append('work_types', s.value);
        })
        casting_calls_services.updateCastingCall(this.casting_call.uuid, form_data).then((response) => {
          this.$emit('update_campaign', response.response);
        });
      } else {
        this.steps['work_types'] = this.selected;
        this.steps.index_actived[1] = this.selected.map((s) => s.index_actived);
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({ name: 'steps_casting_call', params: {step: 'organization'}})
      }
    }
  },
}
</script>
<style>
.active-class-button > .card-radion-button-steps {
  background-color: rgba(114, 103, 240, 0.021) !important;
  border: 0.0625rem solid #5582f6; 
}
.active-class-button > .card-radion-button-steps > .background-left-proposal {
  background-color: #5582f6  !important;
}
</style>